import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component:() => import('../views/HomeView.vue')
  },
  {
    path: '/userdetail',
    name: 'userdetail',
    component: () => import('../views/userdetail.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue'),
    props({ query }) {
      return { code: query.code} //用组件参数的形式把query传递给组件
    },
  },
  {
    path: '/mesmain',
    name: 'mesmain',
    meta: { title: '生产进度' },
    component: () => import('../views/mes/main.vue')
  },
  {
    path: '/moreport',
    name: 'moreport',
    meta: { title: '工序报工' },
    component: () => import('../views/mes/moreport.vue')
  },
  {
    path: '/moreportlist',
    name: 'moreportlist',
    meta: { title: '报工记录' },
    component: () => import('../views/mes/moreportlist.vue')
  },
  {
    path: '/moreportdetail',
    name: 'moreportdetail',
    meta: { title: '工序汇报详细' },
    component: () => import('../views/mes/moreportdetail.vue'),
    props({ query }) {
      return { billno: query.billno} //用组件参数的形式把query传递给组件
    },
  },
  {
    path: '/prdinspect',
    name: 'prdinspect',
    meta: { title: '生产检验' },
    component: () => import('../views/mes/prdinspect.vue')
  },
  {
    path: '/prdinspectlist',
    name: 'prdinspectlist',
    meta: { title: '生产检验记录' },
    component: () => import('../views/mes/prdinspectlist.vue')
  },
  {
    path: '/inspect',
    name: 'inspect',
    meta: { title: '来料检验' },
    component: () => import('../views/mes/inspect.vue'),
    props({ query }) {
      return { qrcode:query.qrcode} //用组件参数的形式把query传递给组件
    },
  },
  {
    path: '/inspectlist',
    name: 'inspectlist',
    meta: { title: '来料检验记录' },
    component: () => import('../views/mes/inspectlist.vue')
  },
  {
    path: '/equdjtask',
    name: 'equdjtask',
    meta: { title: '设备点检' },
    component: () => import('../views/mes/equdjtask.vue')
  },
  {
    path: '/equdjtasklist',
    name: 'equdjtasklist',
    meta: { title: '点检记录' },
    component: () => import('../views/mes/equdjtasklist.vue')
  },
  {
    path: '/equbytask',
    name: 'equbytask',
    meta: { title: '设备保养' },
    component: () => import('../views/mes/equbytask.vue')
  },
  {
    path: '/equbytasklist',
    name: 'equbytasklist',
    meta: { title: '保养记录' },
    component: () => import('../views/mes/equbytasklist.vue')
  },
  {
    path: '/equwxtask',
    name: 'equwxtask',
    meta: { title: '设备维修' },
    component: () => import('../views/mes/equwxtask.vue'),
    props({ query }) {
      return { billno: query.billno} 
    }
  },
  {
    path: '/equwxtaskedit',
    name: 'equwxtaskedit',
    meta: { title: '设备维修' },
    component: () => import('../views/mes/equwxtaskedit.vue'),
    props({ query }) {
      return { billno: query.billno} 
    }
  },
  {
    path: '/equhistory',
    name: 'equhistory',
    meta: { title: '设备履历' },
    component: () => import('../views/mes/equhistory.vue')
  },
  {
    path: '/equwxtasklist',
    name: 'equwxtasklist',
    meta: { title: '保养记录' },
    component: () => import('../views/mes/equwxtasklist.vue')
  },
  {
    path: '/moldbytask',
    name: 'moldbytask',
    meta: { title: '模具保养' },
    component: () => import('../views/mes/moldbytask.vue')
  },
  {
    path: '/moldbytasklist',
    name: 'moldbytasklist',
    meta: { title: '保养记录' },
    component: () => import('../views/mes/moldbytasklist.vue')
  },
  {
    path: '/moldwxtask',
    name: 'moldwxtask',
    meta: { title: '模具维修' },
    component: () => import('../views/mes/moldwxtask.vue'),
    props({ query }) {
      return { billno: query.billno} 
    }
  },
  {
    path: '/moldwxtasklist',
    name: 'moldwxtasklist',
    meta: { title: '维修记录' },
    component: () => import('../views/mes/moldwxtasklist.vue')
  },
  {
    path: '/moldwxtaskedit',
    name: 'moldwxtaskedit',
    meta: { title: ' 模具维修' },
    component: () => import('../views/mes/moldwxtaskedit.vue'),
    props({ query }) {
      return { billno: query.billno} 
    }
  },
  {
    path: '/moldoutstock',
    name: 'moldoutstock',
    meta: { title: '模具领用' },
    component: () => import('../views/mes/moldoutstock.vue')
  },
  {
    path: '/moldoutstocklist',
    name: 'moldoutstocklist',
    meta: { title: '领用记录' },
    component: () => import('../views/mes/moldoutstocklist.vue')
  },
  {
    path: '/moldinstock',
    name: 'moldinstock',
    meta: { title: '模具入库' },
    component: () => import('../views/mes/moldinstock.vue')
  },
  {
    path: '/moldinstocklist',
    name: 'moldinstocklist',
    meta: { title: '入库记录' },
    component: () => import('../views/mes/moldinstocklist.vue')
  },
  {
    path: '/moldhistory',
    name: 'moldhistory',
    meta: { title: '模具履历' },
    component: () => import('../views/mes/moldhistory.vue')
  },
  {
    path: '/sopdetail',
    name: 'sopdetail',
    meta: { title: 'SOP文件' },
    component: () => import('../views/mes/sopdetail.vue'),
    props({ query }) {
      return { mnumber: query.mnumber,qrcode:query.qrcode} //用组件参数的形式把query传递给组件
    },
  },
  {
    path: '/soplist',
    name: 'soplist',
    meta: { title: '查询SOP' },
    component: () => import('../views/mes/soplist.vue'),
    props({ query }) {
      return { qrcode:query.qrcode} //用组件参数的形式把query传递给组件
    },
  },
  {
    path: '/inspectsop',
    name: 'inspectsop',
    meta: { title: 'SOP文件' },
    component: () => import('../views/mes/inspectsop.vue'),
    props({ query }) {
      return { mnumber: query.mnumber,qrcode:query.qrcode} //用组件参数的形式把query传递给组件
    },
  },
  {
    path: '/abnormal',
    name: 'abnormal',
    meta: { title: '异常申报' },
    component: () => import('../views/mes/abnormal.vue')
  },
  {
    path: '/abnormallist',
    name: 'abnormallist',
    meta: { title: '异常申报记录' },
    component: () => import('../views/mes/abnormallist.vue')
  },
  {
    path: '/abnormaldetail',
    name: 'abnormaldetail',
    meta: { title: '异常申报' },
    component: () => import('../views/mes/abnormaldetail.vue'),
    props({ query }) {
      return { billno: query.billno} 
    }
  },
  {
    path: '/abnormalhandle',
    name: 'abnormalhandle',
    meta: { title: '异常处理' },
    component: () => import('../views/mes/abnormalhandle.vue'),
    props({ query }) {
      return { billno: query.billno} 
    }
  },
  {
    path: '/abnormalhandlelist',
    name: 'abnormalhandlelist',
    meta: { title: '异常处理记录' },
    component: () => import('../views/mes/abnormalhandlelist.vue')
  },
  {
    path: '/abnormalhandleedit',
    name: 'abnormalhandleedit',
    meta: { title: '异常处理' },
    component: () => import('../views/mes/abnormalhandleedit.vue'),
    props({ query }) {
      return { billno: query.billno} 
    }
  },
  {
    path: '/gysmain',
    name: 'gysmain',
    meta: { title: '供应商协同' },
    component: () => import('../views/supplier/main.vue')
  },
  {
    path: '/purorderlist',
    name: 'purorderlist',
    meta: { title: '采购订单' },
    component: () => import('../views/supplier/purorderlist.vue')
  },
  {
    path: '/purorderdetail',
    name: 'purorderdetail',
    meta: { title: '采购订单详细' },
    component: () => import('../views/supplier/purorderdetail.vue'),
    props({ query }) {
      return { billno: query.billno} 
    }
  },
  {
    path: '/purreceiveedit',
    name: 'purreceiveedit',
    meta: { title: '送货详细' },
    component: () => import('../views/supplier/purreceiveedit.vue'),
    props({ query }) {
      return { billno: query.billno} 
    }
  },
  {
    path: '/purreceivelist',
    name: 'purreceivelist',
    meta: { title: '送货单列表' },
    component: () => import('../views/supplier/purreceivelist.vue')
  },
  {
    path: '/purreceivedetail',
    name: 'purreceivedetail',
    meta: { title: '送货单详细' },
    component: () => import('../views/supplier/purreceivedetail.vue'),
    props({ query }) {
      return { billno: query.billno} 
    }
  },
  {
    path: '/materialselect',
    name: 'materialselect',
    meta: { title: '物料选择器' },
    component: () => import('../views/supplier/materialselect.vue'),
    props({ query }) {
      return { number: query.number} 
    }
  },
  {
    path: '/supplierpriceedit',
    name: 'supplierpriceedit',
    meta: { title: '供价表明细' },
    component: () => import('../views/supplier/supplierpriceedit.vue'),
    props({ query }) {
      return { number: query.number} 
    }
  },
  {
    path: '/supplierpricelist',
    name: 'supplierpricelist',
    meta: { title: '供价表列表' },
    component: () => import('../views/supplier/supplierpricelist.vue')
  },
  {
    path: '/appayablelist',
    name: 'appayablelist',
    meta: { title: '入库单列表' },
    component: () => import('../views/supplier/appayablelist.vue')
  }
    
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
